// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_1suPXMIvwmE9IC8UV0blEqrQSHQZkyk",
  authDomain: "ifa-nettside.firebaseapp.com",
  projectId: "ifa-nettside",
  storageBucket: "ifa-nettside.appspot.com",
  databaseURL: "https://ifa-nettside-default-rtdb.europe-west1.firebasedatabase.app/",
  messagingSenderId: "531521234643",
  appId: "1:531521234643:web:dd5e3ca72c8a41732bd626",
  measurementId: "G-4TWE8CJ3SY"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getDatabase(app);
export const firestore = getFirestore(app);

export default app
