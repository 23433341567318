import React, {useState, useEffect} from 'react'
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Slider from "react-slick";
import InnleggModell from "../Komponenter/InnleggModell";
import {} from '@heroicons/react/outline';



import { db } from "../firebase";
import {  ref as ref_database, onValue } from 'firebase/database';



const KarusellForInnlegg = () => {


    const [innlegg, setInnlegg] = useState([]);


    const [open, setOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [fullInnlegg, setFullInnlegg] = useState({});




    

    useEffect(() => {
        const innleggRef = ref_database(db);
        onValue(innleggRef, (snapshot) => {
            setInnlegg([])
            const data = snapshot.val();
    
            if(data !== null) {
                Object.values(data).map((innlegg) => {
    
                    setInnlegg(oldArray => [...oldArray, innlegg])
                });
            }
            
        });
    }, [])
    

    const handleModal = (x) => {
        setShowModal(true)
        setFullInnlegg(x)
      }


      
    
    

    
      const NextArrow = ({onClick}) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <FaArrowRight />
          </div>
        )
      }
    
      const PrevArrow = ({onClick}) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            <FaArrowLeft />
          </div>
        )
      }
    
    const [imgIndex, setImgIndex] = useState(0)
    
    const settings = {
      infinite:true,
      lazyLoad: true,
      speed: 300,
      slidesToShow:1,
      centerMode: true,
      centerPadding: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) => setImgIndex(next), 
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };
    
    
      return (
        <div className="relative pt-16 pb-32 container mx-auto px-4">
          <Slider {...settings}>
            {innlegg
            .sort((a,b) => Date.parse(a.dato) - Date.parse(b.dato))
            .map((x, idx) => (
                <div key={idx} className="relative flex flex-wrap pt-10">
                    <div className={idx === imgIndex ? "slide activeSlide" : "slide"}>
                        <div key={x.uuid} className="">
                            <div className="p-12 lg:p-10 xl:p-20">
                                <div className="">
                                    <h6 className="text-2xl font-serif text-blue-800 ">{x.tittel}</h6>
                                    <p className="pt-2 mt-2 mb-4 text-gray-600">
                                    {x.tekst.substring(0, 250)} 
                                    </p>
                                </div>
                                <button onClick={() => handleModal(x)} className="flex hover:pl-2 text-blue-500">
                                    Les Innlegg 
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-5 hover:underline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                    </svg>
                                </button>
                            </div>  
                        </div>  
                    </div>
              </div>
            ))}
          </Slider>

          {showModal && (
                    <InnleggModell 
                    showModal={showModal}
                    setShowModal={setShowModal}
                    {...fullInnlegg}
                    />
                                
                )}
        </div>
      );
    }
export default KarusellForInnlegg