import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { addDoc, setDoc, doc, Timestamp, getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import { UserAuth } from '../Context/AuthContext';
import IFAboat from "../middler/img/ifaoslo.svg"

 
const Yin = () => {
  const [tittel, setTittel] = useState('')
  const [tekst, setTekst] = useState('')
  const [innlegg, setInnlegg] = useState([])
  const [opprettInnlegg, setOpprettInnlegg] = useState(false) 
  const [error, setError] = useState('')
  const navigate = useNavigate()


  const { createUser, user } = UserAuth()


    const openOpprettInnlegg = (oppdater) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTittel(oppdater.tittel)
        setTekst(oppdater.tekst)
        setOpprettInnlegg(true)
    }



    const closeOpprettInnlegg = () => {setOpprettInnlegg(false)}



    const [admin, setAdmin] = useState([]);
    const adminRef = collection(firestore, 'admin');

  const innleggRef = collection(firestore, 'innleggYin')

  const handleSubmit = async (e) => {

      e.preventDefault()
      setError('')
      try {
        await createYinInnlegg(tittel, tekst)
        await getInnleggYin();
        await closeOpprettInnlegg();
      } catch (e) {
        setError(e.message)
        console.log(e.message)
      }

  }

  
  //dytter brukerne som blir registrert inn i databasen for brukere
  const createYinInnlegg = (tittel, tekst) => {
    console.log(tittel, tekst)
        try {

            setDoc(doc(firestore, 'innleggYin', '1'), {
                tittel,
                tekst,
                innleggOpprettet: Timestamp.fromDate(new Date()),
                id: 1
              });
              console.log(tittel, tekst)

        } catch (error) {
            console.log('Error in creating innlegg', error)
            console.log(tittel, tekst)
        }
   console.log(tittel, tekst)

}
    const getInnleggYin = async () => {
        const data = await getDocs(innleggRef);
        setInnlegg(data.docs.map((doc) => ({ ...doc.data()})))
            
    };


    useEffect(() => {
    
        const getadmin = async () => {
            const data = await getDocs(adminRef);
            setAdmin(data.docs.map((doc) => ({ ...doc.data()})))
            
            
        };
        getadmin();
      
       }, []);


    useEffect(() => {

    getInnleggYin();
  
   }, []);

  return (
    <div className=' '>
  
  <section className="pb-14">
    <div className='max-w-[700px] mx-auto md:p-4 flex'>
        
      <div className= {!opprettInnlegg ? "hidden" : "bg-gray-100 w-full text-center md:p-20 p-5 border-0 rounded-2xl shadow-2xl relative md:flex flex-col outline-none focus:outline-none"} >
          <div>
              <h1 className='font-bold py-2'>Opprett innlegg </h1>
          </div>
          <form onSubmit={handleSubmit}>
              <div className="flex flex-col py-2">
              <label className='py-2 font-medium'>Tittel</label>
              <input onChange={(e) => setTittel(e.target.value)} value={tittel} className='border p-3'/>
              </div>
              <div className='flex flex-col py-2'>
              <label className='py-2 font-medium'>Tekst</label>
              <textarea onChange={(e) => setTekst(e.target.value)} value={tekst} rows='10' className='border p-3'/>
              </div>
              <button className='border border-blue-800 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>Publiser</button>
          </form>
          <button onClick={closeOpprettInnlegg} className='border border-blue-800 bg-red-600 hover:bg-red-500 w-full p-4 my-2 text-white'>Avbryt</button>
      </div>
    </div>
  </section>

  <section >

    <div className="container mx-auto px-4">
      <div className="items-center flex flex-wrap">
        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img
            alt="Bildet av ifa-logo"
            className="md:mb-0 mb-10 rounded-lg w-[500px] "
            src={IFAboat}
          />
        </div>
        <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
          
        {innlegg.map(x => {
          return (
              <div key='1' className='whitespace-pre-wrap'>
                  <div className="md:pr-12">
                    <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full gradient">
                      <i className="fas fa-rocket text-xl"></i>
                    </div>
                    <h3 className="text-3xl font-semibold">
                      {x.tittel}
                    </h3>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                      {x.tekst}
                    </p>
                  </div>
                  <div>
                    <button onClick={() => openOpprettInnlegg(x)} className= {!user ? 'hidden' : 'border border-blue-800 bg-gray-500 hover:bg-gray-600 w-52 p-4 my-2 text-white'}>Rediger</button>
                  </div>
                  
              </div>       
            )
          })}
        </div>
      </div>
    </div>

  </section>

    </div>
  );
};

export default Yin