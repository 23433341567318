import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../Context/AuthContext'
import Navbar from "../Komponenter/navbar";

const Logginn = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [brukerIkkeFunnet, setBrukerIkkeFunnet] = useState('') 

  const navigate = useNavigate();
  const { logginn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    setBrukerIkkeFunnet('')
    try {
      await logginn(email, password)
      navigate('/')
    } catch (e) {
      setError(e.message)
      console.log(e.message)
      if(!error) {
        setBrukerIkkeFunnet("Brukeren du har skrevet finnes ikke, eller passordet er feil")
      } 
    }

  };

  return (
    <>
    <Navbar />
  <div className='max-w-[700px] mx-auto p-4 pt-64'>
     
    <div>
      <h1 className='font-bold py-2'>Logg inn</h1>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col py-2">
        <label className='py-2 font-medium'>E-post</label>
        <input onChange={(e) => setEmail(e.target.value)} className='border p-3' type="email"/>
      </div>
      <div className='flex flex-col py-2'>
        <label className='py-2 font-medium'>Passord</label>
        <input onChange={(e) => setPassword(e.target.value)} className='border p-3' type="password"/>
        <div className='text-red-500'>{brukerIkkeFunnet}</div>
      </div>
      <button className='border border-blue-800 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>Logg inn</button>
    </form>
  </div>
    </>
   
  )
}

export default Logginn