import React from 'react'
import ContactForm from '../Komponenter/ContactForm'
import Footer from '../Komponenter/Footer'
import Navbar from '../Komponenter/navbar'
import Medlemskap from '../Komponenter/Medlemskap'

const BliMedlem = () => {


  return (
    <div >
        <Navbar />
        <div className=' max-w-[1200px] mx-auto md:pt-52 md:pb-40 pt-24 md:overflow-visible overflow-hidden md:p-0 p-10'>
          <div className="-mx-4 flex flex-wrap lg:justify-end space-x-24">
         
              <Medlemskap />

              <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                  <ContactForm />
                  
              </div>
            </div>
        </div>

        <Footer/>

    </div>
  )
}

export default BliMedlem