import React from 'react'
import { UserAuth } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Navbar from "../Komponenter/navbar";

//muligens ikke brukt

const Profil = () => {

  const {user, loggUt} = UserAuth();
  const navigate = useNavigate();


  const handleLogout = async (e) => {
    try {
      await loggUt()
      navigate('/')
      console.log("you are logged out")
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
  <>

    { 
    !user ? 
        <Link to='/logginn' className=' hover:text-gray-300' >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>
        </Link> 
        :
        <button onClick={handleLogout}>Logg ut</button>
    }

      
  </>
    
    
  )
}

export default Profil