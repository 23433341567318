import React, {useEffect, useState} from "react";
import { db} from "../firebase";
import {  ref as ref_database, onValue } from 'firebase/database';
import { Link } from 'react-router-dom'
import Navbar from "../Komponenter/navbar";
import KarusellForInnlegg from "../Komponenter/KarusellForInnlegg";
import IFAboat from "../middler/img/ifaoslo.svg"
import IfaFlagg from "../middler/img/ifa_flag.jpg"
import opera from "../middler/img/forsidebildetOpera.jpg"
import ifaverden from "../middler/img/ifaverden.jpg"
import Header from "../Komponenter/Header"
import Footer from "../Komponenter/Footer"



import InnleggModell from "../Komponenter/InnleggModell";
import {} from '@heroicons/react/outline';

export default function Eventer() {


    const [innlegg, setInnlegg] = useState([]);


    const [open, setOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [fullInnlegg, setFullInnlegg] = useState({});

    useEffect(() => {
        const innleggRef = ref_database(db);
        onValue(innleggRef, (snapshot) => {
            setInnlegg([])
            const data = snapshot.val();
    
            if(data !== null) {
                Object.values(data).map((innlegg) => {
    
                    setInnlegg(oldArray => [...oldArray, innlegg])
                });
            }
        });
    }, [])

    

      // Function to check if a date has passed the current date
      const hasPassedCurrentDate = (innlegg) => {
        console.log(innlegg)
          const currentDate = new Date();
          const dateToCheck = new Date(innlegg);
          return dateToCheck < currentDate;
  
      };
      

    const nyeInnlegg = innlegg.filter((x) => !hasPassedCurrentDate(x.dato));

    const eldreInnlegg = innlegg.filter((x) => hasPassedCurrentDate(x.dato));

    
    

    const handleModal = (x) => {
        setShowModal(true)
        setFullInnlegg(x)
      }

  return (
    <>
      <Navbar />
      <main>
        <Header />

        {/* innlegg fra bruker */}
        <section className="pb-20 divide-y-4 border-gray-100">    
              
          <h1 className="flex justify-center pt-16">Kommende medlemsmøter</h1>
          <div className="flex relative pt-16 pb-32 container mx-auto px-4 md:grid grid-cols-4 gap-4">
         
          {nyeInnlegg
            .sort((a,b) => Date.parse(a.dato) - Date.parse(b.dato))
            .map((x, idx) => (

                <div key={idx} className="relative flex flex-wrap pt-10 ">
                  <button onClick={() => handleModal(x)} className="text-left">
                    <div className="rounded-lg shadow-lg h-full hover:scale-105">
                        <div key={x.uuid} className="">
                            <div className="p-12 lg:p-10 xl:p-20">
                                <div className="">
                                    <h6 className="text-2xl font-serif text-blue-800 ">{x.tittel}</h6>
                                    <p>{x.dato}</p>
                                    <p className="pt-2 mt-2 mb-4 text-gray-600">
                                      {x.tekst.substring(0, 50)}...
                                    </p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                  </button>
              </div>
            ))}
       
 
        </div>

        </section>
       
       <section className="pb-20 divide-y-4 border-gray-100">

        <h1 className="flex justify-center">Eldre eventer</h1>

        <div className="flex relative pt-16 pb-32 container mx-auto px-4 md:grid grid-cols-4 gap-4">
            {eldreInnlegg
              .sort((a,b) => Date.parse(a.dato) - Date.parse(b.dato))
              .map((x, idx) => (

                <div key={idx} className="relative flex flex-wrap pt-10 ">
                  <button onClick={() => handleModal(x)} className="text-left">
                    <div className="rounded-lg shadow-lg h-full hover:scale-105">
                        <div key={x.uuid} className="">
                            <div className="p-12 lg:p-10 xl:p-20">
                                <div className="">
                                    <h6 className="text-2xl font-serif text-blue-800 ">{x.tittel}</h6>
                                    <p>{x.dato}</p>
                                    <p className="pt-2 mt-2 mb-4 text-gray-600">
                                      {x.tekst.substring(0, 50)}... 
                                    </p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                  </button>
                </div>
              ))}
          </div>
       </section>
        
        
        {showModal && (
            <InnleggModell 
            showModal={showModal}
            setShowModal={setShowModal}
            {...fullInnlegg}
            />                  
          )}
        <Footer />

      </main>
    </>
  );
}