import { getDownloadURL, ref, uploadBytesResumable, getStorage, listAll } from 'firebase/storage';
import React, { createContext, useContext, useEffect, useState } from 'react';



const StorageContext = createContext()


export const StorageContextProvider = ({children}) => {

    const [progress, setProgress] = useState('0')
    const [progressAdmin, setProgressAdmin] = useState('0')
    const storage = getStorage();
    const listRef = ref(storage, 'files/');
    const listRefBeretning = ref(storage, 'filesBeretning/');
    const listRefAdmin = ref(storage, 'filesAdmin/');
    const [item, setItems] = useState([]);
    const [itemBeretning, setItemsBeretning] = useState([]);
    const [itemAdmin, setItemsAdmin] = useState([]);
    

    //last opp start

    //ikke autentisert
    const lastOppFilerBeretning = (fil) => {


      //lager path for filene
      const lagringsref = ref(storage, `/filesBeretning/${fil.name}`); 
      const uploadTask = uploadBytesResumable(lagringsref, fil);
      
      uploadTask.on("state_changed", (snapshot) => {
              const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
              setProgress(prog)
          },
              () => {
              getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => console.log(url))               
          }, () => {
              window.location.reload(false)
          }
           
          
      ); 
     
  };






    //vanlig brukere
    const lastOppFiler = (fil) => {


        //lager path for filene
        const lagringsref = ref(storage, `/files/${fil.name}`); 
        const uploadTask = uploadBytesResumable(lagringsref, fil);
        
        uploadTask.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                setProgress(prog)
            },
                () => {
                getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => console.log(url))               
            }, () => {
                window.location.reload(false)
            }
             
            
        ); 
       
    };


    //admin
    const lastOppFilerAdmin = (fil) => {


        //lager path for filene
        const lagringsref = ref(storage, `/filesAdmin/${fil.name}`); 
        const uploadTask = uploadBytesResumable(lagringsref, fil);
        
        uploadTask.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                setProgressAdmin(prog)
            },
                () => {
                getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => console.log(url))               
            }, () => {
                window.location.reload(false)
            }
             
            
        ); 
       
    };

    


   
    //last opp slutt



    //last ned start

    //last ned filer Beretning og ikke bruker
    const lastNedFilerBeretning = (fil) => {
        
      getDownloadURL(ref(storage, `/filesBeretning/${fil}`))
      .then((url) => {
       
    
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          console.log(event)
          console.log(blob)
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(xhr.response);
          a.download = `${fil}`;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click(); 
          
        };
        xhr.open('GET', url);
        xhr.send();
    
      })
      .catch((error) => {
        // Handle any errors
      });
  }    


    //presentasjon og ikke bruker
    const lastNedFilerIkkeBruker = (fil) => {
        
        getDownloadURL(ref(storage, `/presentasjon/${fil}`))
        .then((url) => {
         
      
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            console.log(event)
            console.log(blob)
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = `${fil}`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click(); 
            
          };
          xhr.open('GET', url);
          xhr.send();
      
        })
        .catch((error) => {
          // Handle any errors
        });
    }    



    //vanlig brukere
    const lastNedFiler = (fil) => {
        
        getDownloadURL(ref(storage, `/files/${fil}`))
        .then((url) => {
         
      
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            console.log(event)
            console.log(blob)
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = `${fil}`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click(); 
            
          };
          xhr.open('GET', url);
          xhr.send();
      
        })
        .catch((error) => {
          // Handle any errors
        });
    }    

    //admin
    const lastNedFilerAdmin = (fil) => {
        
        getDownloadURL(ref(storage, `/filesAdmin/${fil}`))
        .then((url) => {
         
      
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            console.log(event)
            console.log(blob)
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = `${fil}`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click(); 
            
          };
          xhr.open('GET', url);
          xhr.send();
      
        })
        .catch((error) => {
          // Handle any errors
        });
    }    
    //last ned slutt


    //vis alle filer start


    //vanlig bruker
    



    useEffect(() => {
        listAll(listRef)
    .then((res) => {
        res.items.forEach((itemRef) => { 

            console.log(itemRef);
                   
                setItems((item) => [...item, itemRef]);
               
          },[]);

          
    },[]).catch((error) => {
     console.log(error)
    }); 
      }, []);

//beretning
      useEffect(() => {
        listAll(listRefBeretning)
    .then((res) => {
        res.items.forEach((itemRef) => { 

            console.log(itemRef);
                   
                setItemsBeretning((item) => [...item, itemRef]);
               
          },[]);

          
    },[]).catch((error) => {
     console.log(error)
    }); 
      }, []);



    //admin
      useEffect(() => {
        listAll(listRefAdmin)
    .then((res) => {
        res.items.forEach((itemRef) => { 

            console.log(itemRef);
                   
                setItemsAdmin((item) => [...item, itemRef]);
               
          },[]);

          
    },[]).catch((error) => {
     console.log(error)
    }); 
      }, []);

    
    //vis alle filer slutt


    return (
        <StorageContext.Provider value={{lastOppFiler, lastOppFilerAdmin, lastOppFilerBeretning, lastNedFilerIkkeBruker, lastNedFiler, lastNedFilerAdmin, lastNedFilerBeretning, progress, progressAdmin, item, itemBeretning, itemAdmin, storage}}>
            {children}
        </StorageContext.Provider>
    );

};




export const Storage = () => {
    return useContext(StorageContext)
};