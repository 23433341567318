import React, { useState, useEffect } from 'react'
import { Storage } from '../Context/StorageContext'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from "../Komponenter/navbar";
import {ref as ref_storage, deleteObject } from 'firebase/storage';
import { getDocs, collection } from "firebase/firestore"; 
import { firestore } from '../firebase';
import BeretningBakgrunn from "../middler/img/BakgrunnBeretning.jpg"
import Footer from "../Komponenter/Footer"

import { AiOutlineDownload } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri'


const Beretning = () => {

    const { itemBeretning, lastNedFilerBeretning, progress, lastOppFilerBeretning, storage } = Storage()
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [file, setFile] = useState(null)


    const [bruker, setBruker] = useState([]);
    const brukerRef = collection(firestore, 'bruker');

    const [searchValue, setSearchValue] = useState("")

    const handleNedlastning = async (e) => {
      setError('')
      try {
        const filer = e;
        console.log(filer)
        await lastNedFilerBeretning(filer)
      } catch (error) {
        setError(error.message)
        console.log(error.message)
      }
    }
  
    const handleOpplastning = async (fil) => {
        //e.preventDefault();
        setError('')
        try {
         //const fil = file.target[0].files[0];
          console.log(fil);
          //kaller på funksjonen 
          await lastOppFilerBeretning(fil);  
        } catch (f) {
          setError(f.message)
          console.log(f.message)   
          
            alert("Error! fil ble ikke lastet opp, prøv igjen eller endre navn på fil ")
            window.location.reload(false);
     
        }
    };


    const slettInnlegg = async(x) => {
      await deleteObject(ref_storage(storage, `/filesBeretning/${x}`)) 
      window.location.reload(false);
}



//sjekker om Bruker

 useEffect(() => {
    
  const getbruker = async () => {
      const data = await getDocs(brukerRef);
      setBruker(data.docs.map((doc) => ({ ...doc.data()})))
      
      
  };
  getbruker();

 }, []);

/*

<div key={1} className="w-full items-center justify-center pt-64 bg-grey-lighter"style={{ backgroundImage: `url(${BeretningBakgrunn})`}}>


        <div className='md:absolute md:top-32 md:right-20 flex pb-10 justify-center'>

          <div className="flex items-center">
            <div className="flex space-x-1">
                  <input
                      type="text"
                      className="block w-full px-4 py-2 text-blue-700 bg-white border rounded-full focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Søk..."
                      name="search"
                      value={searchValue}
                      onChange={e => setSearchValue(e.target.value)}
                  />
                  <button className="px-4 text-white bg-blue-500 hover:bg-blue-700 rounded-full ">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                      >
                          <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                      </svg>
                  </button>
            </div>
          </div>
        </div>

        {bruker.map(x => {
        return(
            <div>
                <div className='md:flex md:flex-row md:space-y-0 space-y-10 '>

                
                <div className='flex w-full justify-center'>
                    <label className=" w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 leading-normal">Last opp</span>
                    <input type='file' className="hidden"  onChange={(e) => handleOpplastning(e.target.files[0])}/>
                    
                    </label>
                </div>
                    
                </div>
            

                <div className='flex'>


                <div className="md:flex hidden w-full items-center justify-center pt-3 pb-10 ">
                    <div className="w-64 overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                        <div style={{width: `${progress}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                    </div>
                </div>
                
                </div>
            </div>
        )})}


        

        <div className='md:flex pt-10 md:space-y-0 space-y-20 bg-white'>

          <div className='max-w-[900px] rounded-lg divide-y w-full border mx-auto shadow-lg items-center justify-center'>
            { itemBeretning
            .filter(x => String(x).match(new RegExp(searchValue, "i")))
            .map( a => 
            <div key={a.name} className='flex flex-row md:divide-x pr-4 overflow-contain p-5 '>
              <div className='basis-5/6 p-5 pt-5 my-4 truncate'> {a.name}</div> 
              <div className='flex 2xl:ml-20 basis-1/2 justify-end'>
                <button onClick={() => handleNedlastning(a.name)} className='border md:rounded-none rounded-lg border-blue-800 bg-blue-500 hover:bg-blue-600 md:my-7 md:px-9 my-7 px-1 text-white' >Last ned</button>
                {bruker.map(x => {
                return(
                    <button onClick={() => slettInnlegg(a.name)} className='border md:rounded-none rounded-lg border-red-800 bg-red-500 hover:bg-red-600 md:my-7 md:px-9 my-7 px-2 text-white' >Slett</button>
                )})}
              </div> 
            </div>
            
            
              )} 
          </div>
        </div>
        
        
      </div>
style={{ backgroundImage: `url(${BeretningBakgrunn})`}}
*/
//AiOutlineDownload
//RiDeleteBin5Line
  return (
    <>

      
     <Navbar />
        <main>   
            <div className='h-min-screen h-[1100px] bg-cover bg-center inset-0'style={{ backgroundImage: `url(${BeretningBakgrunn})`}}>  
                <div key={1} className="w-full grayscale-0 items-center justify-center pt-64 bg-grey-lighter ">
                    <div className='text-center pb-16 text-white'>
                        <h1>Årsberetninger og regnskap </h1>
                    </div>
                
                    <div className='md:absolute md:top-32 md:right-20 flex pb-10 justify-center'>

                    <div className="flex items-center">
                        <div className="flex space-x-1">
                            <input
                                type="text"
                                className="block w-full px-4 py-2 text-blue-700 bg-white border rounded-full focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="Søk..."
                                name="search"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                            />
                            <button className="px-4 text-white bg-blue-500 hover:bg-blue-700 rounded-full ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    </div>

                    {bruker.map(x => {
                    return(
                        <div>
                            <div className='md:flex md:flex-row md:space-y-0 space-y-10 '>

                            
                            <div className='flex w-full justify-center'>
                                <label className=" w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                </svg>
                                <span className="mt-2 leading-normal">Last opp</span>
                                <input type='file' className="hidden"  onChange={(e) => handleOpplastning(e.target.files[0])}/>
                                
                                </label>
                            </div>
                                
                            </div>
                            <div className='flex'>

                            <div className="md:flex hidden w-full items-center justify-center pt-3 pb-10 ">
                                <div className="w-64 overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                                    <div style={{width: `${progress}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                                </div>
                            </div>
                            
                            </div>
                        </div>
                    )})}


                    <div className=' pt-10  space-y-20 '>
                        <div className='max-w-[900px] rounded-lg divide-y w-full border mx-auto shadow-lg items-center justify-center'>
                            { itemBeretning
                            .filter(x => String(x).match(new RegExp(searchValue, "i")))
                            .map( a => 
                                <div key={a.name} className='flex flex-row md:divide-x pr-4 overflow-contain p-5 bg-white shadow-lg'>
                                    <div className='basis-5/6 pt-5 my-4 truncate'> {a.name}</div> 
                                    <div className='flex 2xl:ml-20 basis-1/2 justify-end'>
                                        <button onClick={() => handleNedlastning(a.name)} className='  text-blue-500 hover:text-blue-800 px-10' ><AiOutlineDownload size={40}/></button>
                                        {bruker.map(x => {
                                        return(
                                            <button onClick={() => slettInnlegg(a.name)} className='  text-red-500 hover:text-red-700' ><RiDeleteBin5Line size={30}/></button>
                                        )})}
                                    </div> 
                                </div> 
                            )} 
                        </div>
                    </div>
                </div>
            </div>
            
            <div className=' w-full bottom-0 -mb-2'>
                <Footer />
            </div>
        </main>
       
        
      

    </>
  )
}

export default Beretning