import React, { useState, useEffect } from 'react'
import { Storage } from '../Context/StorageContext'
import {ref as ref_storage, deleteObject } from 'firebase/storage';




export default function Modal({showModal, setShowModal, bildet, tittel, tekst, presentasjon}) {



  const { lastNedFilerIkkeBruker } = Storage()
  const [error, setError] = useState('')


  const handleNedlastning = async (e) => {
    setError('')
    try {
      const filer = e;
      console.log(filer)
      await lastNedFilerIkkeBruker(filer)
    } catch (error) {
      setError(error.message)
      console.log(error.message)
    }
  }

  
  return (
    <>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        
        <div onClick={() => setShowModal(false)}
            className="justify-center items-center max-h-full md:flex  overflow-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div onClick={(e) => {e.stopPropagation()}} className="relative w-auto my-6 mx-auto max-w-6xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative md:flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="md:flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {tittel}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="each-frame border-box flex-none ">
                    <div className="main md:flex w-full p-8"> 
                        <div className="md:p-8 my-auto">
                            <img className="object-cover md:w-96" src={bildet} alt=""></img>
                        </div>
              
                        <div class="sub w-full my-auto">
                          <p className="long-text text-lg">{tekst}</p> 
                          {!presentasjon ? (
                         <div>
                         <p className='pt-5'>{presentasjon}</p>  
                            <button onClick={() => handleNedlastning(presentasjon)} className='border md:rounded-none rounded-lg border-blue-800 bg-blue-500 hover:bg-blue-600 md:my-7 md:px-9 my-7 px-1 text-white' >Last ned</button>
                         </div>
                          ) : (
                          <div className='pt-5'>
                            <p>Presentasjon er slettet</p>   
                          </div>
                            )}
                        </div>                       
                    </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
       
     
    </>
  );
}