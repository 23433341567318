import React, { useState } from 'react'
import { Storage } from '../Context/StorageContext'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from "../Komponenter/navbar";


const Upload = () => {

    const { lastOppFiler, progress, lastNedFiler } = Storage()
    const [error, setError] = useState('')
    const navigate = useNavigate();
   

    const handleOpplastning = async (e) => {
        e.preventDefault();
        setError('')
        try {
          const fil = e.target[0].files[0];
          console.log(fil);
          //kaller på funksjonen 
          await lastOppFiler(fil);  
          navigate('/filer')         
        } catch (f) {
          setError(f.message)
          console.log(f.message)        
        }
    };

  return (

    <>
     <Navbar />
     
     <div className='max-w-[900px] mx-auto p-60 w-full'>
     
     <div>
       <h1 className='font-bold py-2'>Last opp valgt fil</h1>
     </div>

        <form onSubmit={handleOpplastning}>
            <input type="file" className='input' />
            <button type='submit'>Last opp</button>
        </form>
        <h3>Uploaded { progress } %</h3>
    
    </div>
    </>

  
  )

  //
}

export default Upload