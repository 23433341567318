import React from 'react'

const Medlem = () => {
  return (
    <div className="pt-44">
    <h4 className="text-dark mb-1 text-xl font-bold">Styrets medlemmer</h4>
    <ul className=''>
      <li>Andreas Bullen (styrets leder)</li>
      <li>Agnes Brandt</li>
      <li>Kari Alice Røssland Frønsdal</li>
      <li>Monica Sivertsen</li>
      <li>Martine Dyrhol-Bjørstad</li>
      <li>Wensing Li</li>
      <li>Christine Buer</li>
      <li>Eivind Furuseth</li>
      <li>Simen Skjold Søgaard (styresekretær)</li>
    </ul>
  </div>
  )
}

export default Medlem