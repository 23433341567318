import React, {useState, useEffect, Component} from 'react'
import InnleggModell from "../Komponenter/InnleggModell";

import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import Slider from "react-slick";

import { db } from "../firebase";
import {  ref as ref_database, onValue } from 'firebase/database';

const Header = () => {

    const [innlegg, setInnlegg] = useState([]);


    const [showModal, setShowModal] = useState(false)
    const [fullInnlegg, setFullInnlegg] = useState({});

    useEffect(() => {
        const innleggRef = ref_database(db);
        onValue(innleggRef, (snapshot) => {
            setInnlegg([])
            const data = snapshot.val();
    
            if(data !== null) {
                Object.values(data).map((innlegg) => {
    
                    setInnlegg(oldArray => [...oldArray, innlegg])
                });
            }
            
        });
    }, [])


    const NextArrow = ({onClick}) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <AiFillCaretRight size={40} />
          </div>
        )
      }
    
      const PrevArrow = ({onClick}) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            <AiFillCaretLeft size={40} />
          </div>
        )
      }
    
    const [imgIndex, setImgIndex] = useState(0)

    const settings = {
        infinite:true,
        lazyLoad: true,
        speed: 300,
        slidesToShow:1,
        centerMode: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerPadding: 0,
        beforeChange: (current, next) => setImgIndex(next), 
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                }
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              }
        ]
      };

      const array = [
        {
          name: 'jesper',
        },
        {
          name: 'Amund',
        },
        {
          name: 'karl',
        },
      ]


      const handleModal = (x) => {
        setShowModal(true)
        setFullInnlegg(x)
      }
     

  return (
    <div>

 
    <Slider {...settings} >

      
        {innlegg
        .sort((a,b) => Date.parse(a.dato) - Date.parse(b.dato))
        .slice(0,3)
        .map((x, idx) => (


        <div key={x.idx} className=" text-white relative md:h-[700px] ">
           <img src={x.bildet} alt="IFA event bilder" className='w-full h-full object-cover absolute md:mix-blend-normal mix-blend-overlay'></img>
          <div className="container px-4 mx-auto flex flex-wrap flex-col md:flex-row md:absolute ">
            
            <div className="mt-96  flex-col md:w-2/5 md:pl-32 justify-center items-start text-left">
              <p className="md:pt-0 md:-mt-20 pt-32 tracking-loose w-full"></p>
                <h1 className="my-4 md:text-5xl font-serif leading-tight text-shadow">
                  {x.tittel}
                </h1> 

                <button onClick={() => handleModal(x)} className="flex hover:pl-5 pl-4 mb-10 text-blue-900 mix-blend-hard-light bg-white p-3 ">
                  Les Mer 
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-5 hover:underline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                  </svg>
               </button>   

            </div>            
          </div>          
        </div>

        
        
        ))}



    </Slider>


    {showModal && (
      <InnleggModell 
      showModal={showModal}
      setShowModal={setShowModal}
      {...fullInnlegg}
      />
                  
    )}
    
    </div>  
  )   
}

export default Header