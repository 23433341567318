import { set, ref as ref_database, onValue, remove, update } from 'firebase/database';
import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import { db } from '../firebase'
import { getDownloadURL, ref as ref_storage, uploadBytesResumable, getStorage, deleteObject } from 'firebase/storage';
import { uid } from 'uid'
import { getDocs, collection } from "firebase/firestore"; 
import { firestore } from '../firebase';
import Navbar from "../Komponenter/navbar";


const Innlegg = () => {

    const storage = getStorage();
    const [progress, setProgress] = useState('0')
    const [tittel, setTittel] = useState('');
    const [tekst, setTekst] = useState('');
    const [dato, setDato] = useState('');
    const [bildet, setBildet] = useState('');
    const [presentasjon, setPresentasjon] = useState('');
    const [innlegg, setInnlegg] = useState([]);
    const [rediger, setRediger] = useState(false);
    const [midlertidigID, setmidlertidigID] = useState('');
    const [error, setError] = useState('');
    const [date, setDate] = useState()

    const [deleteOpen, setDeleteOpen] = useState(true);
    
    const handleDelete = (x) => {
        slettInnlegg(x)
        setDeleteOpen(false)
    }

    const [searchValue, setSearchValue] = useState("")

    const [bruker, setBruker] = useState([]);
    const brukerRef = collection(firestore, 'bruker')
    const adminRef = collection(firestore, 'admin')
   

    const handleOpplastning = async (e) => {
        e.preventDefault();
        setError('')
        try {
            if(tekst === "" || tittel === "" || dato === "") {
                alert("Feil! Vennligst fyll ut dato, tittel, tekst")

            } else if (!bildet && !presentasjon){
                skrivTilDatabasen(tittel, tekst, dato)  
                toast("Innlegg lastet opp uten bildet! og uten pres", { type: "success" });
                console.log(dato)
                alert("du har lastet opp et innlegg uten bildet og presentasjon")

                window.location.reload(false);

            } else if (!bildet){
                skrivTilDatabasenMedPresentasjon(tittel, tekst, dato, presentasjon)  
                console.log(dato)
                alert("du har lastet opp et innlegg med presentasjon uten bildet")

               
            } else if (!presentasjon){
                skrivTilDatabasenMedBildet(tittel, dato, tekst, bildet)
                alert("du har lastet opp et innlegg med bildet og uten presentasjon")
                setProgress(0); //toast og nullstille progress bar funker ikke :/
                
            } else {
                skrivTilDatabasenMedBildetOgPresentasjon(tittel, dato, tekst, bildet, presentasjon)
                alert("du har lastet opp et innlegg med bildet og presentasjon")
               
            } 

        } catch (e) {
          
          setError(e.message)
          console.log(e.message)
        }
    
    };

    //rediger databasen context
    const redigerInnlegg = (oppdater) => {
        scrollToTop()
        setRediger(true)
        setmidlertidigID(oppdater.uuid)
        setTekst(oppdater.tekst)
        setTittel(oppdater.tittel)
        
    }

    const publiserEndring = async (e) => {
        e.preventDefault();
        if(!bildet && !presentasjon) { //endrer uten bildet og presentasjon
           
            alert("du oppdaterer uten bildet og uten presentasjon")
            update(ref_database(db, `${midlertidigID}`), {
                tittel,
                tekst,
                dato,
                uuid: midlertidigID,
            })

            setDato('')
            setTekst('')
            setTittel('')
            setRediger(false)
        } else if (!presentasjon) { //endrer uten presentasjon og med bildet
            alert("du har oppdatert med bare bildet")
                //lager path for filene
            const lagringsref = ref_storage(storage, `/bilder/${midlertidigID}`); 
            const uploadTask = uploadBytesResumable(lagringsref, bildet);
        
            uploadTask.on("state_changed", (snapshot) => {
                    const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                    setProgress(prog)
                
                }, console.log(bildet, tittel, tekst, dato),
                    () => {
                        console.log(bildet, tittel, tekst, dato)
                    
                    getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        update(ref_database(db, `${midlertidigID}`), {
                            tittel,
                            tekst,
                            dato,
                            bildet:url,
                            bildetNavn: bildet,
                            uuid: midlertidigID,
                        })

                        setDato('')
                        setTekst('')
                        setTittel('')
                        setRediger(false)
                        
                    })
                } 
            ); 
        } else if (!bildet) { //endrer med presentasjon og uten bildet
            alert ("du har oppdatert med bare presentasjon")
            //const lagringsref = ref_storage(storage, `/bilder/${midlertidigID}`); 
            const lagringsrefpres = ref_storage(storage, `/presentasjon/${presentasjon.name}`) 
            const lagringsrefpres1 = ref_storage(storage, `/files/${presentasjon.name}`)
            
            
            const uploadTaskpres = uploadBytesResumable(lagringsrefpres, presentasjon)
            const uploadTaskpres1 = uploadBytesResumable(lagringsrefpres1, presentasjon)

        
            uploadTaskpres.on("state_changed", (snapshot) => {
                    const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                    setProgress(prog)
                
                }, console.log( tittel, tekst, dato, presentasjon),
                    () => {
                        console.log( tittel, tekst, dato, presentasjon)
                    
                    getDownloadURL(uploadTaskpres.snapshot.ref)
                    .then((url) => {
                        update(ref_database(db, `${midlertidigID}`), {
                            tittel,
                            dato,
                            tekst,
                            presentasjon: presentasjon.name,
                            uuid: midlertidigID
                        })

                        setDato('')
                        setTekst('')
                        setTittel('')
                        setRediger(false)
                        
                    })
                } 
            ); 
        } else { //endrer både presentasjon og bildet
            alert("du har oppdatert med bildet og presentasjon")
                //lager path for filene
            const lagringsref = ref_storage(storage, `/bilder/${midlertidigID}`); 
            const lagringsrefpres = ref_storage(storage, `/presentasjon/${presentasjon.name}`) 
            const lagringsrefpres1 = ref_storage(storage, `/files/${presentasjon.name}`)
            
            
            const uploadTaskpres = uploadBytesResumable(lagringsrefpres, presentasjon)
            const uploadTaskpres1 = uploadBytesResumable(lagringsrefpres1, presentasjon)

            const uploadTask = uploadBytesResumable(lagringsref, bildet);
        
            uploadTask.on("state_changed", (snapshot) => {
                    const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                    setProgress(prog)
                
                }, console.log(bildet, tittel, dato, tekst, presentasjon),
                    () => {
                        console.log(bildet, tittel, dato, tekst, presentasjon)
                    
                    getDownloadURL(uploadTask.snapshot.ref)
                    .then((url) => {
                        update(ref_database(db, `${midlertidigID}`), {
                            tittel,
                            dato,
                            tekst,
                            bildet:url,
                            bildetNavn: bildet,
                            presentasjon: presentasjon.name,
                            uuid: midlertidigID,
                        })

                        setDato('')
                        setTekst('')
                        setTittel('')
                        setRediger(false)
                        
                    })
                } 
            ); 
        }
    }

    //slette fra databasen og storage
    const slettInnlegg = async(slettInnlegg) => {
            await remove(ref_database(db, `/${slettInnlegg.uuid}`))
            await deleteObject(ref_storage(storage, `/bilder/${slettInnlegg.uuid}`))  
            await deleteObject(ref_storage(storage, `/presentasjon/${slettInnlegg.presentasjon}`))
            await deleteObject(ref_storage(storage, `/files/${slettInnlegg.presentasjon}`))           
    }


    const slettPresentasjon = async(slettInnlegg) => { 
        await deleteObject(ref_storage(storage, `/presentasjon/${slettInnlegg.presentasjon}`))       
    }       

    //skriver til databasen med bildet
    const skrivTilDatabasenMedBildet = (tittel, dato, tekst, bildet) => {
            console.log(bildet, tittel, dato, tekst)

        //lager path for filene
        const uuid = uid();
        const now = new Date();
        const date = now.getTime()
        const lagringsref = ref_storage(storage, `/bilder/${uuid}`); 
        const uploadTask = uploadBytesResumable(lagringsref, bildet);
       
        uploadTask.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                setProgress(prog)
            
            }, console.log(bildet, tittel, dato, tekst),
                () => {
                    
                   
                getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                    set(ref_database(db, `${uuid}`), {    
                        tittel,
                        dato,
                        tekst,
                        bildet: url,
                        bildetNavn: bildet.name,
                        uuid,
                        date
                    });

                    setDato('')
                    setTekst('')
                    setTittel('')
                    setBildet('')
                    
                    
                })
            } 
        ); 
    };

    //skriver til databasen med presentasjon
    const skrivTilDatabasenMedPresentasjon = (tittel, dato, tekst, presentasjon) => {
        console.log( tittel, dato, tekst, presentasjon)

        //lager path for filene
        const uuid = uid();
        const now = new Date();
        const date = now.getTime()
        const lagringsrefpres = ref_storage(storage, `/presentasjon/${presentasjon.name}`) 
        const lagringsrefpres1 = ref_storage(storage, `/files/${presentasjon.name}`) 
        const uploadTaskpres = uploadBytesResumable(lagringsrefpres, presentasjon)
        const uploadTaskpres1 = uploadBytesResumable(lagringsrefpres1, presentasjon)
    
        uploadTaskpres.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                setProgress(prog)
            
            }, console.log(tittel, dato, tekst, presentasjon),
                () => {
                    
               
                getDownloadURL(uploadTaskpres.snapshot.ref)
                .then((url) => {
                    set(ref_database(db, `${uuid}`), {    
                        tittel,
                        dato,
                        tekst,
                        presentasjon: presentasjon.name,
                        uuid,
                        date
                    })

                    setDato('')
                    setTekst('')
                    setTittel('')
                    setBildet('')
                    setPresentasjon('')

                })
            }
        ); 
    };


    //skriver til databasen med bildet og presentasjon
    const skrivTilDatabasenMedBildetOgPresentasjon = (tittel, dato, tekst, bildet, presentasjon) => {
        console.log(bildet, tittel, dato, tekst, presentasjon)

        //lager path for filene
        const uuid = uid();
        const now = new Date();
        const date = now.getTime()
        const lagringsref = ref_storage(storage, `/bilder/${uuid}`);
        const lagringsrefpres = ref_storage(storage, `/presentasjon/${presentasjon.name}`) 
        const lagringsrefpres1 = ref_storage(storage, `/files/${presentasjon.name}`) 
        const uploadTask = uploadBytesResumable(lagringsref, bildet);
        const uploadTaskpres = uploadBytesResumable(lagringsrefpres, presentasjon)
        const uploadTaskpres1 = uploadBytesResumable(lagringsrefpres1, presentasjon)
    
        uploadTask.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100) 
                setProgress(prog)
            
            }, console.log(bildet, tittel, dato, tekst, presentasjon),
                () => {
                    
               
                getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                    set(ref_database(db, `${uuid}`), {    
                        tittel,
                        dato,
                        tekst,
                        bildet: url,
                        bildetNavn: bildet.name,
                        presentasjon: presentasjon.name,
                        uuid,
                        date
                    })
                   

                    setDato('')
                    setTekst('')
                    setTittel('')
                    setBildet('')
                    setPresentasjon('')
                    
                    
                
                })
            }
        ); 
    };

   
    
    //lese fra databasen

    useEffect(() => {
        const innleggRef = ref_database(db);
        onValue(innleggRef, (snapshot) => {
            setInnlegg([])
            const data = snapshot.val();

            if(data !== null) {
                Object.values(data).map((innlegg) => {

                    setInnlegg(oldArray => [...oldArray, innlegg])
                });
            }
            
        });
    }, [])


    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };



      //sjekker om bruker/admin tilgang
      useEffect(() => {
    
        const getbruker = async () => {
            const data = await getDocs(brukerRef);
            setBruker(data.docs.map((doc) => ({ ...doc.data()})))
            
            
        };
        getbruker();
      
       }, []);


    //skrive til databasen uten bildet
    const skrivTilDatabasen = (tittel, tekst, dato) => {
        const uuid = uid();
        const now = new Date();
        const date = now.getTime();
        set(ref_database(db, `${uuid}`), {    
            tittel,
            tekst,
            dato,
            uuid, 
            date
        });
        
       setDato('') 
       setTekst('')
       setTittel('')
    };



  return (
    <>

{!deleteOpen && (
   
    <div>

        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        <div onclick={() => setDeleteOpen(true)} class="w-full md:w-1/3 mx-auto pt-52 fixed inset-0 z-40 ">
            <div class="flex flex-col p-5 rounded-lg shadow bg-white">
                <div class="flex">
                <div>
                    <svg class="w-6 h-6 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
                </div>

                <div class="ml-3">
                    <h2 class="font-semibold text-gray-800">Delete Alert Title With Large Action</h2>
                    <p class="mt-2 text-sm text-gray-600 leading-relaxed">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum impedit ipsam nam quam! Ab accusamus aperiam distinctio doloribus, praesentium quasi reprehenderit soluta unde?</p>
                </div>
                </div>

                <div class="flex items-center mt-3">
                <button class="flex-1 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md">
                    Avbryt
                </button>

                <button onClick={() => slettInnlegg()} class="flex-1 px-4 py-2 ml-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md">
                    Slett
                </button>
                </div>
            </div>
        </div>
         
    </div>
    )}
    
    <Navbar />
    {bruker.map(x => {
        return(

                  
        <div key={1} className='max-w-[700px] mx-auto pt-36 p-4'>



            <div className='md:absolute md:top-32 md:right-20 flex pb-10 justify-center'>

                <div className="flex items-center">
                    <div className="flex space-x-1">
                        <input
                            type="text"
                            className="block w-full px-4 py-2 text-blue-700 bg-white border rounded-full focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            placeholder="åååå.mm.dd"
                            name="search"
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                        <button className="px-4 text-white bg-blue-500 hover:bg-blue-700 rounded-full ">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>


            {/* <div className='md:absolute md:top-32 md:left-20 flex pb-10 justify-center'> 

                <div className="md:flex items-center hidden">
                    <div className="space-x-1 rounded-lg h-96 overflow-scroll divide-y w-full border mx-auto shadow-lg">
                    {innlegg.map(x => {

                    return(

                        <section key={x.uuid} className="pb-20">
                            <div className='text-center' >
                                <p> {x.dato} </p>
                                <p> {x.tittel} </p> 
                            </div>
                        </section>
                        
                    ) }) }
                    </div>
                </div>
            </div> */}


            <div className='pb-32'>

                <h1 className='font-bold py-10 justify-text-center'>Legg til et nytt innlegg</h1>
                
                <form className='form-control' onSubmit={handleOpplastning} >

                    <div className='form-control flex flex-col py-2'>
                        <label className='py-2 font-medium'>Sett Dato</label>
                        <input onChange={(e) => setDato(e.target.value)} value={dato} type="date" className='border-solid border-2 border-black p-1 w-52' />
                    </div>

                    <div className='form-control flex flex-col py-2'>
                        <label className='py-2 font-medium'>Tittel på innlegget</label>
                        <input onChange={(e) => setTittel(e.target.value)} value={tittel} type="textarea" className='border-solid border-2 border-black p-1 w-52' />
                    </div>
                    
                    <div className='form-control flex flex-col py-2'>
                        <label className='py-2 font-medium'>HovedTekst</label>
                        <textarea onChange={(e) => setTekst(e.target.value)} value={tekst} type="textarea"  rows="7" className='border-solid border-2 border-black' />
                    </div>

                    <p>Legg til presentasjon</p>
                    <div className='form-control'>
                        <input type="file"  onChange={(e) => setPresentasjon(e.target.files[0])}/>
                            <div className="relative pt-1">
                        </div>
                    </div>

                    <p className='pt-6' >Legg til bildet</p>
                    <div className='form-control'>
                        <input type="file"  onChange={(e) => setBildet(e.target.files[0])}/>
                        <div className="relative pt-1">
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                                <div style={{width: `${progress}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
                            </div>
                        </div>
                    </div>
                    

                    {!rediger ? (
                        <button onClick={handleOpplastning} className='border border-blue-800 bg-blue-500 hover:bg-blue-600 w-full p-4 my-2 text-white'>Publiser</button>
                    ) : (
                        <div>
                            <button onClick={publiserEndring} className='border border-green-800 bg-green-500 hover:bg-green-600 w-10/12 p-4 my-2 text-white'>Oppdater</button>
                            <button onClick={() => {
                                setRediger(false)
                                    setDato('')
                                    setTekst('')
                                    setTittel('')
                            }} className='border border-blue-800 bg-red-500 w-2/12 hover:bg-red-600 p-4 my-2 text-white'>Avbryt</button>
                        </div>
                    )}
                
                </form>
            </div>
           
            <div>
                {innlegg
                .filter(x => String(x.dato).match(new RegExp(searchValue, "i")))
                .map(x => {

                    const now = new Date();

                    const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;

                    const timeDiffInMs = now.getTime() - x.date

                    if(timeDiffInMs >= thirtyDaysInMs && !presentasjon){
                        console.log('Date is older than 30 days');
                        slettPresentasjon(x)
                        }else{
                            console.log('Date is not older than 30 days');
                        }

                        return(

                            <section key={x.uuid} className="pb-20">
                                <div className='text-center' >
                                    <h1> {x.dato} </h1>
                                    <h1> {x.tittel} </h1>
                                    <p> {x.tekst} </p>
                                    <img src={x.bildet} />
                                    <p> {x.presentasjon} </p>
                                    <div className='p-5'>
                                        <button onClick={() => redigerInnlegg(x)} className='border border-blue-800 bg-gray-500 hover:bg-gray-600 w-52 p-4 my-2 text-white'>Rediger</button>
                                        <button onClick={() => slettInnlegg(x)} className='border border-blue-800 bg-red-500 hover:bg-red-600 w-52 p-4 my-2 text-white'>Slett</button>
                                    </div>
                                </div>
                            </section>
                            
                        ) }) }

            </div>
        </div> 
    )})}
    </>
  )
}

export default Innlegg