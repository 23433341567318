import React from 'react'
import Navbar from '../Komponenter/navbar'
import Footer from '../Komponenter/Footer'
import Yin from '../Komponenter/Yin'


const YinPage = () => {
  
  
  
  
    
  
  
  
return (
    <div>
    <Navbar />

        <div className=' mx-auto md:pt-52 md:pb-40 pt-24 md:overflow-visible overflow-hidden md:p-0 p-10'>
            <Yin />
        </div>

     

       
    <Footer />
    </div>
  )
}

export default YinPage