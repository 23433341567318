import React from 'react'
import Footer from '../Komponenter/Footer'
import Navbar from '../Komponenter/navbar'
import vedtekter from "../middler/img/BakgrunnVedtekter.jpg"


const Vedtekter = () => {
  return (
    <main>
        <Navbar />
        <div className='bg-cover bg-center grayscale' style={{ backgroundImage: `url(${vedtekter})`}}>
            <div className='max-w-[700px] mx-auto px-10 pt-64 bg-white' >

            <div className=''>
                <div className='pb-36 font-bold text-center'>

                    <h2 className='text-3xl p-2'> Vedtekter </h2>
                    <p className='p-2' >for</p>
                    <p className='p-2' >Den norske underavdeling av International Fiscal Association (IFA)</p>

                </div>

                <div>


                    <h3 className='font-bold text-xl text-center' > § 1 (Foreningens navn og sete)</h3>
                    <p className='pb-10'> Foreningens navn er Den norske underavdeling av International Fiscal Association IFA (”the Norwegian Branch of the International Fiscal Association”) Foreningens sete skal være i Oslo. </p>

                    <h3 className='font-bold text-xl text-center' > § 2 (Formål)</h3>
                    <p className='pb-10'> Foreningens formål er studier og utvikling av internasjonal og komparativ rett med hensyn til offentlige finanser, og spesielt internasjonal og komparativ skatte- og avgiftsrett og økonomiske aspekter av skattesystemet. </p>

                    <h3 className='font-bold text-xl text-center' >§ 3 (Aktivitet) </h3>
                    <p className='pb-10'> Foreningen skal assistere og tilrettelegge aktivitetene til International Fiscal Association (heretter benevnt ”IFA”) i egenskap av Norsk underavdeling av IFA, og skal i samarbeid med IFAs sentralorganisasjon realisere Foreningens formål ved å:
                    </p>
                        <ul className='list-disc list-inside pb-14' >
                            <li className='-m-8' >fremme vitenskapelig arbeid</li>
                            <li className='-m-8'>arrangere og støtte konferanser og medlemsmøter</li>
                            <li className='-m-8'>støtte utgivelse av publikasjoner og innlegg i publikasjoner</li>
                            <li className='-m-8'>innkreve medlemskontingent</li>
                            <li className='-m-8'>forestå andre aktiviteter som styret finner formålstjenelig</li>
                        </ul>
                    

                    <h3 className='font-bold text-xl text-center' >§ 4 (Medlemmer) </h3>
                    <p className='pb-10'> Medlemmer av Foreningen kan være fysiske personer eller juridiske personer som anses for å være skikket til å bidra til studier og utvikling av internasjonal og komparativ skatte- og avgiftsforskning. </p>

                    <h3 className='font-bold text-xl text-center' > § 5 (Innmelding)</h3>
                    <p className='pb-10'> En person som ønsker å bli medlem kan sende inn søknad om medlemskap i en nærmere angitt form til Styret. </p>

                    <h3 className='font-bold text-xl text-center' >§ 6 (Opphør av medlemskap) </h3>
                    <p className='pb-10'> Et medlemskap kan opphøre ved: </p>
                    <ul className='list-disc list-inside pb-14' >
                            <li className='-m-8' >avvisning av IFA</li>
                            <li className='-m-8'>styrets mottakelse av medlemmets skriftlige oppsigelse av medlemskapet</li>
                            <li className='-m-8'>årsmøtets beslutning om oppsigelse av medlemmer</li>
                            <li className='-m-8'>innkreve medlemskontingent</li>
                            <li className='-m-8'>styrets beslutning om oppsigelse av medlemmet basert på manglende kontingentbetaling.</li>
                        </ul>

                    <h3 className='font-bold text-xl text-center' > § 7 (Årsmøtet) </h3>
                    <p className='pb-10'> Årsmøtet skal være Foreningens øverste organ.

                        Styret skal innkalle til ordinært Årsmøte en gang i året.

                        Styret skal innkalle til ekstraordinært Årsmøte når dette anses nødvendig, eller når en anmodning om innkalling er fremsatt av minst 1/5 av alle medlemmene.

                        Beslutninger på Årsmøtet krever tilslutning av et flertall av tilstedeværende medlemmer, med mindre annet er bestemt i Vedtektene.
                    </p>

                    <h3 className='font-bold text-xl text-center' > § 8 (Styret) </h3>
                    <p className='pb-10'> 

                        Foreningen skal ledes av et styre bestående av fra fem til ni medlemmer. Årsmøtet skal velge styre og styrets leder. Lederen skal representere foreningen utad.  
                    </p>

                    <h3 className='font-bold text-xl text-center' > § 9 (Kontingent)</h3>
                    <p className='pb-10'> Medlemmene skal betale en kontingent som bestemmes av Årsmøtet. </p>

                    <h3 className='font-bold text-xl text-center' > § 10 (Regnskapsperiode)</h3>
                    <p className='pb-10'> Regnskapsperioden for foreningen skal være kalenderåret. </p>

                    <h3 className='font-bold text-xl text-center' > § 11 (Revisor)</h3>
                    <p className='pb-10'> Foreningen skal ha en revisor valgt av Årsmøtet for en periode på to år av gangen. </p>

                    <h3 className='font-bold text-xl text-center' > § 12 (Fastsettelse av regnskap)</h3>
                    <p className='pb-10'> Styret skal utarbeide årsberetning og årsregnskap som skal forelegges Årsmøtet for godkjennelse.</p>

                    <h3 className='font-bold text-xl text-center' > § 13 (Vedtektsendringer)</h3>
                    <p className='pb-10'> 
                    Endringer av Vedtektene krever tilslutning av minst 2/3 av tilstedeværende medlemmer på Årsmøtet.
                    </p>

                    <h3 className='font-bold text-xl text-center' > § 14 (Oppløsning)</h3>
                    <p className='pb-10'> Oppløsning av Foreningen krever tilslutning av minst 2/3 av samtlige medlemmer.</p>



                </div>

            
            
            </div>
            
            

            </div>
        </div>

        <Footer/>

    </main>
  )
}

export default Vedtekter