import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../Context/AuthContext';
import { addDoc, setDoc, doc, Timestamp } from "firebase/firestore";
import { firestore } from '../firebase';


 
const Registrer = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const navigate = useNavigate()

  const { createUser, user } = UserAuth()

  const handleSubmit = async (e) => {

    if (confirmedPassword === password) {

      e.preventDefault()
      setError('')
      try {
        await createUser(email, password)
        await createUserDocument(email)
        navigate('/')
      } catch (e) {
        setError(e.message)
        console.log(e.message)
      }

    } else {
      alert("Passordet samsvarer ikke")
    }
   
  }

  
  //dytter brukerne som blir registrert inn i databasen for brukere
  const createUserDocument = (email) => {
    console.log(user)
        try {
            setDoc(doc(firestore, 'users', `${user.auth.lastNotifiedUid}`), {
                email,
                registrert: Timestamp.fromDate(new Date()),
                admin: false,
                bruker: true,
                id: `${user.auth.lastNotifiedUid}`
              });
              console.log(email)
        } catch (error) {
            console.log('Error in creating user', error)
            console.log(email)
        }
   console.log(email)
}

  return (
    <div className='max-w-[700px] mx-auto my-16 p-4'>
      <div>
        <h1 className='font-bold py-2'>Registrer Bruker</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col py-2">
          <label className='py-2 font-medium'>E-post</label>
          <input onChange={(e) => setEmail(e.target.value)} className='border p-3' type="email"/>
        </div>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Passord</label>
          <input onChange={(e) => setPassword(e.target.value)} className='border p-3' type="password"/>
        </div>
        <div className='flex flex-col py-2'>
          <label className='py-2 font-medium'>Bekreft Passord</label>
          <input onChange={(e) => setConfirmedPassword(e.target.value)} className='border p-3' type="password"/>
        </div>
        <button className='border border-blue-800 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>Registrer</button>
      </form>
        <p className='py-2'>
          Har du allerede en bruker? <Link to='/logginn' className='underline text-black hover:text-gray-300'> logg inn.</Link>
        </p>
    </div>
  );
};

export default Registrer