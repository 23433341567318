import React, {createContext, useContext, useEffect, useState} from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from "../firebase";



const UserContext = createContext()

export const AuthContextProvider = ({children}) => {


    //setter opp funksjonene som skal brukes i appen
    const [user, setUser] = useState({})

    
    const createUser = (email, password) =>  {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    const loggUt = () => {
        return signOut(auth) 
    }

    const logginn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    //informasjon om bruker
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log(currentUser)
            setUser(currentUser)
        })
        return () => {
            unsubscribe();
        }
    },[])

    //sender funksjoner til appen så det kan brukes
    return (
        <UserContext.Provider value={{createUser, user, loggUt, logginn, auth}}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext)
};
