import React, { useState, useEffect } from 'react'
import { collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore"; 
import { firestore } from '../firebase';
import { UserAuth } from '../Context/AuthContext'
import Navbar from "../Komponenter/navbar";

const Brukere = () => {

    const [brukere, setBrukere] = useState([]);
    const [error, setError] = useState();

    const {user} = UserAuth();

    
   const brukereref = collection(firestore, 'users')


   const giAdmin = async (bruker) => {
      setError('')
      try {
       await updateDoc(doc(firestore, "users", bruker), {
            admin: true
        })
      } catch (bruker) {
        setError(bruker.message)
        console.log(bruker.message)
      }
  }

  const taVekkAdmin = async (bruker) => {
    setError('')
    try {
     await updateDoc(doc(firestore, "users", bruker), {
          admin: false
      })
    } catch (bruker) {
      setError(bruker.message)
      console.log(bruker.message)
    }
}
        

   useEffect(() => {
    
    const getbrukere = async () => {
        const data = await getDocs(brukereref);
        setBrukere(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})))
        
        
    };
    getbrukere();

   }, []);

    const slettBruker =  async (id) => {
     
    const brukerDoc = doc(firestore, "users", id);
        await deleteDoc(brukerDoc)

   }



    
  return (
    <>
    <Navbar />
    <div className='w-full items-center justify-center pt-64 bg-grey-lighter'>

    
      <div className='max-w-[900px] p-5 rounded-lg divide-y w-full border mx-auto shadow-lg items-center justify-center'>
              {brukere.map(x => {
                  return(
                  <div key={x.id} >
                      
                            {!x.admin ? (
                          <div className='flex flex-row pr-2 overflow-contain'>
                              <div className='basis-5/6 pt-5 pl-9 my-4 truncate' > 
                                 {x.email}
                              </div>
                              <div className='flex divide-x ml-20 basis-1/2 justify-end'>
                                <button onClick={() => {giAdmin(x.id)}} className='border border-gray-800 bg-gray-500 hover:bg-gray-600 p-4 my-4 text-white'>Admin</button>
                                <button onClick={() => {slettBruker(x.id)}} className='border border-red-800 bg-red-500 hover:bg-red-600 p-4 my-4 text-white'>Slett bruker</button> 
                              </div>
                          </div>
        
                      ) : (
                          <div className='flex flex-row pr-2 overflow-contain'>
                            <div className='basis-5/6 pl-9 pt-5 my-4 truncate'>
                              {x.email}
                            </div>
                            <div className='flex ml-20 basis-1/2 justify-end'>
                              <button onClick={() => {taVekkAdmin(x.id)}} className='border border-green-800 bg-green-500 hover:bg-green-600 p-4 my-2 text-white'>Admin</button>
                              <button onClick={() => {slettBruker(x.id)}} className='border border-red-800 bg-red-500 hover:bg-red-600 p-4 my-2 text-white'>Slett bruker</button> 
                            </div>                                                     
                          </div>
                      )}
      
                  </div>
                  )
              })}
          
      </div>
    </div>
    </>
  );
}

export default Brukere