import React, { Children, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { UserAuth } from '../Context/AuthContext'
import { getDoc, doc} from "firebase/firestore"; 
import { firestore } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from "../firebase";

const SikkerhetsRoutes = ({children}) => {
    const {user} = UserAuth()

    const navigate = useNavigate()


    if (!user) {
      return <Navigate to='/' />
    }

    const userID = async (x) => {


      try {
        const brukerDoc = doc(firestore, "users", x);
        const docsnap = await getDoc(brukerDoc)

          console.log(docsnap.exists())

          if (docsnap.exists() === false) {
            navigate('/')
            console.log("bruker ikke tilgang")
          } else {
            // doc.data() will be undefined in this case
            console.log("bruker tilgang");
          }
      } catch (error) {
        console.log(error)
        
        if (error === "FirebaseError: Missing or insufficient permissions.") {
          navigate('/')
        }
      }
     
      
  
          
     }

     onAuthStateChanged(auth, async () => {
      await userID(user.uid)
     })

    
    /*if (!user) {
        return <Navigate to='/' />
    }*/
   
  return children
}

export default SikkerhetsRoutes