import React from "react";
import { Routes, Route } from "react-router-dom";
import Logginn from "./Komponenter/Logginn";
import Registrer from "./Komponenter/Registrer";
import { AuthContextProvider } from "./Context/AuthContext";
import SikkerhetsRoutes from "./middler/SikkerhetsRoutes";
import Upload from "./Komponenter/LastOpp";
import { StorageContextProvider } from "./Context/StorageContext";
import Filer from "./Komponenter/Filer";
import Innlegg from "./Komponenter/Innlegg";
import Brukere from "./Komponenter/Brukere";
import Hjemmeside from "./Pages/Hjemmeside";
import BliMedlem from "./Pages/BliMedlem";
import Vedtekter from "./Pages/Vedtekter";
import Beretning from "./Pages/Beretning";
import Eventer from "./Pages/Eventer"
import KontaktOss from "./Pages/KontaktOss";
import YinPage from "./Pages/YinPage";

function App() {
  return (
    <div>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<StorageContextProvider><Hjemmeside/></StorageContextProvider>}></Route>
            <Route path="/logginn" element={<Logginn/>}></Route>
            <Route path="/BliMedlem" element={<BliMedlem/>}></Route>
            <Route path="/KontaktOss" element={<KontaktOss/>}></Route>
            <Route path="/Vedtekter" element={<Vedtekter/>}></Route>
            <Route path="/YinPage" element={<YinPage/>}></Route>
            <Route path="/Beretning" element={<StorageContextProvider><Beretning/></StorageContextProvider>}></Route>
            <Route path="/Eventer" element={<StorageContextProvider><Eventer/></StorageContextProvider>}></Route>
            <Route path="/registrer" element={<SikkerhetsRoutes><Registrer/></SikkerhetsRoutes>}></Route>
            <Route path="/lastopp" element={<StorageContextProvider><Upload/> </StorageContextProvider>}></Route>
            <Route path="/filer" element={<StorageContextProvider><><Filer/></></StorageContextProvider>}></Route>
            <Route path="/innlegg" element={<StorageContextProvider><Innlegg/></StorageContextProvider>}></Route>
            <Route path="/brukere" element={<SikkerhetsRoutes><Brukere/></SikkerhetsRoutes>}></Route>
          </Routes>
        </AuthContextProvider>
    </div>
  );
}

export default App;
