import React, {useState, useEffect} from "react";
import {MenuIcon, XIcon} from '@heroicons/react/outline';
import { Link } from 'react-router-dom'
import { UserAuth } from '../Context/AuthContext'
import ifalogohvit from '../middler/img/ifalogohvit.svg'
import { CgProfile } from "react-icons/cg";
import { addDoc, setDoc, doc, Timestamp, getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import HrefLink from './HrefLink'
import Profil from './Profil'

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleClick = () => {setNavbarOpen(!navbarOpen)}

  const [DropDownOpen, setDropDownOpen] = useState(false);
  const [ChildDropDown, setChildDropDown] = useState(false);
  
  const handleDropDown = () => {setDropDownOpen(!DropDownOpen)}
  const handleDropDownChild = () => {setChildDropDown(!ChildDropDown)}


  const [tittel, setTittel] = useState('')
  const [link, setLink] = useState('')
  const [innlegg, setInnlegg] = useState([])
  const [opprettInnlegg, setOpprettInnlegg] = useState(false) 
  const [error, setError] = useState('')


  const {user} = UserAuth()




  const innleggRef = collection(firestore, 'innleggYin')

  const handleSubmit = async (e) => {

      e.preventDefault()
      setError('')
      try {
        await createHrefLink(tittel, link)
        await getHrefLink();
      } catch (e) {
        setError(e.message)
        console.log(e.message)
      }

  }

  
  //dytter brukerne som blir registrert inn i databasen for brukere
  const createHrefLink = (tittel, link) => {
    console.log(tittel, link)
        try {

            setDoc(doc(firestore, 'innleggYin', '1'), {
                tittel,
                link,
                innleggOpprettet: Timestamp.fromDate(new Date()),
                id: 1
              });
              console.log(tittel, link)

        } catch (error) {
            console.log('Error in creating innlegg', error)
            console.log(tittel, link)
        }
   console.log(tittel, link)

}
    const getHrefLink = async () => {
        const data = await getDocs(innleggRef);
        setInnlegg(data.docs.map((doc) => ({ ...doc.data()})))
            
    };



  useEffect(() => {

    getHrefLink();
  
   }, []);




  return (
    <div className= 'w-screen h-[80px] z-50 bg-standard-blue fixed drop-shadow-lg' >
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
        <h1 className='text-3xl font-bold mr-4 sm:text-4xl'><a><img className="w-20" src={ifalogohvit} alt="ifalogo"/></a></h1>
        </div>

        <div className='px-10 flex justify-end items-center w-full h-full text-white'>
          <ul className='hidden md:flex text-white'>
          <li className='hover:text-gray-300'><Link to='/'>Hjem</Link></li>
          <li className='hover:text-gray-300'><Link to='/Eventer'>Eventer</Link></li>
          <li className='hover:text-gray-300'><Link to='/YinPage'>YIN</Link></li>
          <li>
            <div onClick={handleDropDown} className="flex hover:text-gray-300">
              <button>Om oss</button>  <svg class="ml-1 w-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </div>
            <div>
              <ul className={!DropDownOpen ? "hidden" : "absolute bg-white text-sm text-gray-700 dark:text-gray-400" }aria-labelledby="dropdownNavbarButton">
                <Link to='/BliMedlem'>  <li className="block px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Bli medlem</li></Link>
                <Link to='/vedtekter'><li className="block px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Vedtekter</li></Link>
                <Link to='/Beretning'> <li className="block px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Årsberetninger og regnskap</li></Link>
                <Link to='/KontaktOss'> <li className="block px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Kontakt oss</li> </Link>
                <HrefLink />
              </ul>
            </div>
          </li>
            <li><Link to='/filer' className= {!user ? 'hidden' : 'hover:text-gray-300'}>Filer</Link></li>
            <li><Link to='/innlegg' className= {!user ? 'hidden' : 'hover:text-gray-300'}>Innlegg</Link> </li>
            <li><Link to='/brukere' className= {!user ? 'hidden' : 'hover:text-gray-300'} >Brukere</Link> </li>
            <li><Link to='/registrer' className= {!user ? 'hidden' : 'hover:text-gray-300'}>Registrer</Link></li>
            <li>
              <Profil />
            </li>
          </ul>
        </div>

         
        <div className='md:hidden' onClick={handleClick}>
          {!navbarOpen ? <MenuIcon className='m-8 w-6 text-white' /> : <XIcon className='m-8 w-6 text-white' /> }
          
        </div>
      
      
      </div>

      
      <ul className={!navbarOpen ? 'hidden' :  'absolute bg-zinc-200 w-full px-0'}>
        <li className='border-b-2 border-zinc-300 w-full hover:bg-white'><Link to='/'>Hjem</Link></li>
        <li className='border-b-2 border-zinc-300 w-full hover:bg-white'><Link to='/BliMedlem'>Medlemskap</Link></li>
        <li className='border-b-2 border-zinc-300 w-full hover:bg-white'><Link to='/Vedteker'>Vedtekter</Link></li>
        <li className='border-b-2 border-zinc-300 w-full hover:bg-white'><Link to='/Eventer'>Eventer</Link></li>
        <li className='border-b-2 border-zinc-300 w-full hover:bg-white'><Link to='/Beretning'>Beretninger</Link></li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/filer' >Filer</Link> </li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/innlegg' >Innlegg</Link> </li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/brukere' >Brukere</Link> </li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/profil'>Profil</Link></li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/registrer'>Registrer</Link></li>
        <li className={!user ? 'hidden' : 'border-b-2 border-zinc-300 w-full hover:bg-white'}><Link to='/logginn' >Logg inn</Link> </li>
      </ul>

      
    </div>
  );
}