import React, {useEffect, useState} from "react";
import { db} from "../firebase";
import {  ref as ref_database, onValue } from 'firebase/database';
import { Link } from 'react-router-dom'
import Navbar from "../Komponenter/navbar";
import KarusellForInnlegg from "../Komponenter/KarusellForInnlegg";
import IFAboat from "../middler/img/ifaoslo.svg"
import IfaFlagg from "../middler/img/ifa_flag.jpg"
import opera from "../middler/img/forsidebildetOpera.jpg"
import ifaverden from "../middler/img/ifaverden.jpg"
import Header from "../Komponenter/Header"
import Footer from "../Komponenter/Footer"

export default function Landing() {


  return (
    <>
      <Navbar />
      <main>
        <Header />
        {/* header */}
      {/*} <div className="lg:pt-24 2xl:pt-52 gradient text-white">
          <div className="container px-4 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            
            <div className="mt-10 -mb-20 md:flex flex-col md:w-2/5 justify-center items-start text-center md:text-left">
              <p className="md:pt-0 md:-mt-20 pt-32 tracking-loose w-full">Noe smart sakt</p>
              <h1 className="my-4 md:text-5xl font-serif leading-tight">
                Den norske underavdeling av IFA
              </h1>
              <p className="leading-normal text-2xl mb-8">
                undertittel for mer utdypelse
              </p>
            </div>
            
            <div className=" w-full md:pl-[400px] md:w-3/5 py-6 text-center">
              <img className="mx-auto md:w-full xl:w-4/5 z-50" src={IFAboat} alt="ifa_logo" />
            </div>
          </div>

          {/*Bølger... 
          <div className="relative -mt-12 lg:-mt-25">
            <svg viewBox="0 0 1428 174" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
                  <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                    <path
                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                    opacity="0.100000001"
                  ></path>
                  <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
                </g>
                <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
                  <path
                    d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
      */}


       
        {/* første seksjon med generell info */}
        <section className="relative pb-52 md:pt-52 pt-20">
    
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="front bildet ifa"
                  className="max-w-full md:mb-0 mb-10 rounded-lg shadow-lg"
                  src={IfaFlagg}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full gradient">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    International Fiscal Association 
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    (forkortet IFA) ble stiftet i 1938 og har sitt sete i Rotterdam i Nederland. Foreningen har underavdelinger (”branches”) i ca 50 land og har tyngdepunkt i Europa og det amerikanske kontinent. De fleste medlemmer er jurister, men en rekke økonomer og revisorer er også medlemmer. Medlemmene kommer fra privat praksis, fra offentlig administrasjon og fra universiteter og høyskoler.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="hover:underline">
                          <Link to='/BliMedlem'> Bli Medlem </Link> 
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="hover:underline">
                          <Link to='/vedtekter'> Vedtekter </Link> 
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="hover:underline">
                          <Link to='/Beretning'> Beretninger </Link> 
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </section>

        {/* innlegg fra bruker */}
        <section className="pb-20 border-y-4 border-gray-100">    
              <KarusellForInnlegg></KarusellForInnlegg>
        </section>
       
        {/* tredje seksjon med bakgrunn skillet */}
        <section className="pb-20 bg-standard-blue -mt-32">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full pt-4 text-white md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Foreningens formål
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                 ”the study and advancement of international and comparative law in regard to public, finance, specifically international and comparative fiscal law and the financial and economic aspects of taxation”. Foreningen sentralt fremmer sitt formal først og fremst gjennom arrangement av en årlig kongress, men også ved forskningsprosjekter og publiseringer. 
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                IFAs kongresser er formodentlig det viktigste skattefaglige møtepunkt i det internasjonale skattemiljøet. Det publiseres to bind med kongressrapporter i forkant av hver kongress, og mange av seminarinnleggene blir senere bearbeidet og publisert. Foreningen har et nært samarbeid med International Bureau of Fiscal Documentation i Amsterdam. I tillegg holder gjerne underavdelingene møter, arrangementer og lignende.
                </p>
              </div>

              <div className="w-full p-10 md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-beige">
                  <img
                    alt="gruppebildet"
                    src={IfaFlagg}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    
                    <h4 className="text-xl font-bold text-black">
                      Medlem
                    </h4>
                    <p className="text-md font-light mt-2 text-black">
                    Alle jurister, økonomer og andre som har en viss erfaring med skattespørsmål på et relativt avansert nivå, kan bli medlem av IFA gjennom den norske underavdeling.
                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* siste seksjon med mere generell info */}
        <section className="relative py-52 ">
    
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="Bildet av opera"
                  className="max-w-full md:mb-0 mb-10 rounded-lg shadow-lg"
                  src={opera}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full gradient">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Den norske underavdeling av IFA
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                   – ofte omtalt som IFA Norge – ble stiftet i 1976 og har etter hvert etablert seg som det sentrale diskusjonsforum for skatterettslige spørsmål. Foreningen holder fra seks til åtte møter i året, samt et julemøte. IFA Norge har hvert år siden stiftelsen sendt nasjonalrapporter til IFA kongressene. Et høydepunkt i IFA Norges historie var arrangementet av IFA kongressen i Oslo i slutten av august 2002. Arrangementet var meget vellykket både faglig, sosialt, arrangementsmessig og økonomisk.
                  </p>
                  {/*<ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            info (linker)
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">info (linker)</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 gradient mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">info (linker)</h4>
                        </div>
                      </div>
                    </li>
                  </ul>*/}
                </div>
              </div>
            </div>
          </div>
    
        </section>

        <Footer />

      </main>
    </>
  );
}