import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { addDoc, setDoc, doc, Timestamp, getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import { UserAuth } from '../Context/AuthContext';

const BliMedlem = () => {


  const [tittel, setTittel] = useState('')
  const [tekst, setTekst] = useState('')
  const [innlegg, setInnlegg] = useState([])
  const [opprettInnlegg, setOpprettInnlegg] = useState(false) 
  const [error, setError] = useState('')
  const navigate = useNavigate()


  const { createUser, user } = UserAuth()


    const openOpprettInnlegg = (oppdater) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTittel(oppdater.tittel)
        setTekst(oppdater.tekst)
        setOpprettInnlegg(true)
    }



    const closeOpprettInnlegg = () => {setOpprettInnlegg(false)}



    const [admin, setAdmin] = useState([]);
    const adminRef = collection(firestore, 'admin');

  const innleggRef = collection(firestore, 'innleggMedlem')

  const handleSubmit = async (e) => {

      e.preventDefault()
      setError('')
      try {
        await createMedlemInnlegg(tittel, tekst)
        await getInnleggMedlem();
        setOpprettInnlegg(false)
      } catch (e) {
        setError(e.message)
        console.log(e.message)
      }

  }

  
  //dytter brukerne som blir registrert inn i databasen for brukere
  const createMedlemInnlegg = (tittel, tekst) => {
    console.log(tittel, tekst)
        try {

            setDoc(doc(firestore, 'innleggMedlem', '1'), {
                tittel,
                tekst,
                innleggOpprettet: Timestamp.fromDate(new Date()),
                id: 1
              });
              console.log(tittel, tekst)

        } catch (error) {
            console.log('Error in creating innlegg', error)
            console.log(tittel, tekst)
        }
   console.log(tittel, tekst)

}
    const getInnleggMedlem = async () => {
        const data = await getDocs(innleggRef);
        setInnlegg(data.docs.map((doc) => ({ ...doc.data()})))
            
    };


    useEffect(() => {
    
        const getadmin = async () => {
            const data = await getDocs(adminRef);
            setAdmin(data.docs.map((doc) => ({ ...doc.data()})))
            
            
        };
        getadmin();
      
       }, []);


    useEffect(() => {

    getInnleggMedlem();
  
   }, []);

  return (
    <div className='className="w-full px-4 lg:w-1/2 xl:w-6/12'>
      <section className="pb-14">
        <div className='max-w-[700px] mx-auto md:p-4 flex'>
          <div className= {!opprettInnlegg ? "hidden" : "bg-gray-100 w-full text-center md:p-20 p-5 border-0 rounded-2xl shadow-2xl relative md:flex flex-col outline-none focus:outline-none"} >
              <div>
                <h1 className='font-bold py-2'>Opprett innlegg </h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col py-2">
                  <label className='py-2 font-medium'>Tittel</label>
                  <input onChange={(e) => setTittel(e.target.value)} value={tittel} className='border p-3'/>
                </div>
                <div className='flex flex-col py-2'>
                  <label className='py-2 font-medium'>Tekst</label>
                  <textarea onChange={(e) => setTekst(e.target.value)} value={tekst} rows='10' className='border p-3'/>
                </div>
                <button className='border border-blue-800 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>Publiser</button>
              </form>
              <button onClick={closeOpprettInnlegg} className='border border-blue-800 bg-red-600 hover:bg-red-500 w-full p-4 my-2 text-white'>Avbryt</button>
          </div>
        </div>
      </section>

      <div >
      {innlegg.map(x => {
        return (
        <div className="mb-12 max-w-[570px] lg:mb-0">
          <h2
              className="text-dark mb-6 text-[32px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px]"
          >
              {x.tittel}
          </h2>
            <p className="text-body-color mb-9 text-base leading-relaxed">
             {x.tekst}
            </p> 
            <div>
              <button onClick={() => openOpprettInnlegg(x)} className= {!user ? 'hidden' : 'border border-blue-800 bg-gray-500 hover:bg-gray-600 w-52 p-4 my-2 text-white'}>Rediger</button>
            </div>    
        </div>
           )
          })}
      </div>
    </div>
  )
}

export default BliMedlem