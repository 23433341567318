import React, {useState, useEffect} from "react";
import {MenuIcon, XIcon} from '@heroicons/react/outline';
import { Link } from 'react-router-dom'
import { UserAuth } from '../Context/AuthContext'
import ifalogohvit from '../middler/img/ifalogohvit.svg'
import { CgProfile } from "react-icons/cg";
import { addDoc, setDoc, deleteDoc, doc, Timestamp, getDocs, collection } from "firebase/firestore";
import { firestore } from '../firebase';
import { uid } from 'uid'

const HrefLink = () => {

    const [ChildDropDown, setChildDropDown] = useState(false);

    const handleDropDownChild = () => {setChildDropDown(!ChildDropDown)}
  
  
    const [tittel, setTittel] = useState('')
    const [link, setLink] = useState('')
    const [innlegg, setInnlegg] = useState([])
    const [opprettInnlegg, setOpprettInnlegg] = useState(false) 
    const [error, setError] = useState('')
    const [admin, setAdmin] = useState([]);
  
    const {user} = UserAuth()

  
    const uuid = uid();
  
    const closeOpprettInnlegg = () => {setOpprettInnlegg(false)}
    const openOpprettInnlegg = () => {setOpprettInnlegg(true)}
    const innleggRef = collection(firestore, 'hrefLink')
  
    const handleSubmit = async (e) => {

        e.preventDefault()
        setError('')
        try {
          await createHrefLink(tittel, link)
          await getHrefLink();
          closeOpprettInnlegg();
        } catch (e) {
          setError(e.message)
          console.log(e.message)
        }

    }
    
  
    //dytter brukerne som blir registrert inn i databasen for brukere
    const createHrefLink = (tittel, link) => {
      console.log(tittel, link)
        try {
  
            setDoc(doc(firestore, 'hrefLink', `${uuid}`), {
                tittel,
                link,
                innleggOpprettet: Timestamp.fromDate(new Date()),
                id: uuid
            });
            console.log(tittel, link, uuid)
  
        } catch (error) {
            console.log('Error in creating innlegg', error)
            console.log(tittel, link)
        }
     console.log(tittel, link)
  
    }

    const getHrefLink = async () => {
        const data = await getDocs(innleggRef);
        setInnlegg(data.docs.map((doc) => ({ ...doc.data()})))      
    };

    const slettLink =  async (id) => {    
        const innleggRefSlett = doc(firestore, 'hrefLink', id);
        await deleteDoc(innleggRefSlett)
    }

    useEffect(() => {
      getHrefLink();
     
    }, []);


  return (
    <div>
        <div className={!opprettInnlegg ? "hidden" : 'absolute w-[500px] right-16 top-80'}> 
            <div className= "absolute bg-gray-100 w-full text-center md:p-20 p-5 border-0 rounded-2xl shadow-2xl md:flex flex-col outline-none focus:outline-none" >
                <div>
                    <h1 className='font-bold py-2'>Opprett Link </h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col py-2">
                        <label className='py-2 font-medium'>Navn på Link</label>
                        <input onChange={(e) => setTittel(e.target.value)} value={tittel} className='border p-3'/>
                    </div>
                    <div className='flex flex-col py-2'>
                        <label className='py-2 font-medium'>Link</label>
                        <textarea onChange={(e) => setLink(e.target.value)} value={link} rows='1' className='border p-3'/>
                    </div>
                    <button className='border border-blue-800 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>Legg til Link</button>
                </form>
                <button onClick={closeOpprettInnlegg} className='border border-blue-800 bg-red-600 hover:bg-red-500 w-full p-4 my-2 text-white'>Avbryt</button>
            </div>
        </div>

        <li className="flex px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <button onClick={handleDropDownChild}>Lenker til Nettsider</button>  <svg class="ml-1 w-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <div>
                <ul className={!ChildDropDown ? "hidden" : "absolute bg-white text-sm text-gray-700 dark:text-gray-400" }>
                    <div className="flex justify-center">
                        <button onClick={openOpprettInnlegg} className={!user ? 'hidden' : "border border-blue-800 bg-blue-600 hover:bg-blue-500 w-10 text-white"}>
                            +
                        </button> 
                    </div>

           
                    {innlegg.map(x => {
                        return (
                            <li className="flex justify-between bg-gray-100 px-4 hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-white">
                                <a href={x.link} className="hover:underline pr-5">{x.tittel}</a>
                                <button onClick={() => {slettLink(x.id)}} className={!user ? 'hidden' : "border border-red-800 bg-red-600 hover:bg-red-500 w-10 text-white"}>x</button>
                            </li>
                        )
                    })}
                

                </ul>
            </div>
        </li>
    </div>
  )
}

export default HrefLink