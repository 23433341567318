import React from 'react'
import { Link } from 'react-router-dom'
import BineraLogo from "../middler/img/bineraLogo.png"

const Footer = () => {
  return (
    
    <footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8 dark:bg-gray-900 inset-x-0 bottom-0">
        <div class="sm:flex sm:items-center sm:justify-between">
            <a href="https://binera.no/" class="flex items-center mb-4 sm:mb-0">
                <img src={BineraLogo} class="mr-3 h-8" alt="Logo" />
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
            </a>
            <ul class="md:flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
                <li className='hover:underline' > 
                    <Link to='/BliMedlem'>Bli Medlem</Link>
                </li>
                <li className='hover:underline' >
                    <Link to='/Vedtekter'>Vedtekter</Link>
                </li>
                <li className='hover:underline' > 
                    <Link to='/Beretning'>Beretninger</Link>
                </li>
                <li className='hover:underline' >
                    <Link to='/BliMedlem'>Kontakt oss</Link>
                </li>
            </ul>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 text-center dark:text-gray-400">© 2022 <a href="https://flowbite.com/" class="hover:underline">Binera™</a>. All Rights Reserved.
        </span>
    </footer>

  )
}

export default Footer